var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ProjectIntroduction"},[_c('heade'),_c('div',{staticClass:"cen_wrap"},[_c('div',{staticClass:"cen"},[_c('ul',{staticClass:"con_box"},_vm._l((_vm.keyProjects),function(item,index){return _c('li',{key:index},[_c('router-link',{attrs:{"to":{
              path: _vm.pathUrl,
              query: { id: item.id },
            }}},[_vm._v(_vm._s(item.name))])],1)}),0)]),_c('div',{staticClass:"link_box"},[_c('div',{staticClass:"link"},[_c('router-link',{staticClass:"add",attrs:{"to":"/application/ProjectIntroduction"}},[_vm._v("课题研究介绍")])],1),_vm._l((_vm.bookCategorys),function(item,index){return _c('div',{key:index,staticClass:"link"},[_c('router-link',{attrs:{"to":{
            path: _vm.path2,
            query: { id: item.id },
          }}},[_vm._v(_vm._s(item.name))])],1)}),_c('div',{staticClass:"link"},[_c('router-link',{attrs:{"to":"AuxiliaryReading1"}},[_vm._v("人教版全国中小学传统文化教学一体机")])],1)],2)]),_c('foot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }